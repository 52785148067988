import { useState, useRef, useLayoutEffect, useEffect } from "react"
import { useScrollPositionState } from "../contexts"

export const useScrollAnimation = () => {
  const [elementPosition, setElementPosition] = useState(0)
  const [showAnimation, setShowAnimation] = useState(false)

  const ref = useRef(null)
  const scrollPosition = useScrollPositionState()

  const adjustmentPoint = 300

  // set elementPosition only one time
  useLayoutEffect(() => {
    if (ref.current) {
      setElementPosition(
        ref.current.getBoundingClientRect().top -
          window.innerHeight +
          adjustmentPoint
      )
    }
  }, [])

  useEffect(() => {
    if (!showAnimation) {
      if (elementPosition < scrollPosition) {
        setShowAnimation(true)
      }
    }
  }, [showAnimation, elementPosition, scrollPosition])

  return [ref, showAnimation]
}
