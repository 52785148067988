import React, { createContext, useContext } from "react"
import { useToggle } from "../hooks"

const initialValues = {
  isMenuOpen: false,
  toggleMenu: () => {},
}

const MenuButtonContext = createContext(initialValues)

export const MenuButtonProvider = ({ children }) => {
  const { isToggled, toggle } = useToggle(initialValues.isMenuOpen)

  return (
    <MenuButtonContext.Provider
      value={{
        isMenuOpen: isToggled,
        toggleMenu: toggle,
      }}
    >
      {children}
    </MenuButtonContext.Provider>
  )
}

export const useMenuButtonState = () => useContext(MenuButtonContext)
