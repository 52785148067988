import { useLayoutEffect } from "react"

export const useScrollFreeze = isMenuOpen => {
  useLayoutEffect(() => {
    if (isMenuOpen) {
      // document.body does not work for Gatsby
      const original = window.getComputedStyle(
        document.documentElement
      ).overflow

      document.documentElement.style.overflow = "hidden"

      return () => {
        document.documentElement.style.overflow = original
      }
    }
  }, [isMenuOpen])
}
