import { useState, useEffect } from "react"
import { useScrollPositionState } from "../contexts"

export const useShowButton = (threshold = 400) => {
  const [showButton, setShowButton] = useState(false)
  const scrollPosition = useScrollPositionState()

  useEffect(() => {
    if (threshold < scrollPosition) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [threshold, scrollPosition])

  return showButton
}
