import React, { createContext, useContext } from "react"
import { useScrollUpDownListener } from "../hooks"

const ScrollPositionContext = createContext(0)

export const ScrollPositionProvider = ({ children }) => {
  const scrollPosition = useScrollUpDownListener()

  return (
    <ScrollPositionContext.Provider value={scrollPosition}>
      {children}
    </ScrollPositionContext.Provider>
  )
}

export const useScrollPositionState = () => useContext(ScrollPositionContext)
